/* You can add global styles to this file, and also import other style files */
@import "styles/variables";

@import "@fd/core/styles";

@import "styles/angular-material/overwrites";

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
