// ----------------------------------------------------------------------------
// This file contains modifications made directly to Angular Material. Styles
// should be added here judiciously as we want to avoid too many deviations.
// Whenever possible, make changes using the CSS Custom Property API.
//
// Note: FD Core also includes Angular Material style overwrites. This file is
//       included in case any non-standard FD Core changes are required.
// ----------------------------------------------------------------------------

.mat-mdc-card-header-text {
  width: 100%;
}
